import { Link } from 'react-router-dom';
import React, {useState, useCallback } from "react";
import { AutoComplete } from "primereact/autocomplete";
import axios from "axios";
import debounce from "lodash.debounce";
import trigger from '../Component/images/trigger.png';
import Flight_datepickers from './Flight_datepickers';
import Flight_datepicker_oneway from './Flight_datepicker_oneway';
import PassngerDetails from './PassngerDetails';

function Flight_auto_complete() {

  const [activeTab, setActiveTab] = useState(1); // Initialize state for the active tab

  const handleTabClick = (index) => {
    setActiveTab(index); // Update the active tab when a tab is clicked
  };


    const [date, setDate] = useState(null);
    const [departureValue, setDepartureValue] = useState('');
    const [destinationValue, setDestinationValue] = useState('');
    const [departureItems, setDepartureItems] = useState([]);
    const [destinationItems, setDestinationItems] = useState([]);
    const [error, setError] = useState('');

    const fetchSuggestions = useCallback(
        debounce(async (query, type) => {
            try {
                let url = '';
                if (type === 'departure') {
                    url = 'https://cors-anywhere.herokuapp.com/https://travrun.com/index.php/ajax/get_airport_code_list';
                } else if (type === 'arrived') {
                    url = 'https://cors-anywhere.herokuapp.com/https://travrun.com/index.php/ajax/get_airport_code_list';
                }

                const response = await axios.get(url, {
                    params: {
                        term: query,
                        type: 'international'
                    }
                });

                const results = response.data.results || response.data;
                if (!Array.isArray(results)) {
                    throw new Error('');
                }

                if (type === 'departure') {
                    setDepartureItems(results);
                } else if (type === 'arrived') {
                    setDestinationItems(results);
                }

                setError('');
            } catch (error) {
                console.error("Error fetching data:", error);
                setError('Failed to fetch data. Please try again.');
            }
        }, 300),
        []
    );

    const searchDeparture = (event) => {
        fetchSuggestions(event.query, 'departure');
    };

    const searchDestination = (event) => {
        fetchSuggestions(event.query, 'arrived');
    };

    const swapValues = () => {
        const temp = departureValue;
        setDepartureValue(destinationValue);
        setDestinationValue(temp);
    };
  return (
    <div class="tab-pane" id="flights">
    <div class="row gx-lg-2 g-3">

    <div class="d-flex align-items-center justify-content-between flex-wrap">
                  <div class="flx-start mb-sm-0 mb-2">
                  <div className="form-check form-check-inline active_engine">
                    
                  <div className="">
        <span
          className={activeTab === 0 ? 'active' : ''}
          onClick={() => handleTabClick(1)}
        >
           <input class="form-check-input" type="radio" name="trip" id="return" value="option1" checked />
           <label class="form-check-label" for="return">Return</label>
        </span>
        <span
          className={activeTab === 1 ? 'active' : ''}
          onClick={() => handleTabClick(0)}
        >
       <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="trip" id="oneway" value="option2" />
                      <label class="form-check-label" for="oneway">One Way</label>
                    </div>
        </span> 
        
      </div>
      </div>
      </div>
      
     
     
                 
                  
                 
                </div>


                 

        <div class="col-xl-5 col-lg-5 col-md-12">
          <div class="row gy-3 gx-lg-2 gx-3">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 position-relative">
              <div class="form-group hdd-arrow mb-0 fts fts_imgs">
              <AutoComplete
                                    placeholder="Departure"
                                    
                                    value={departureValue}
                                    suggestions={departureItems}
                                    completeMethod={searchDeparture}
                                    onChange={(e) => setDepartureValue(e.value)}
                                    field="label"
                                />
                                {error && <div className="error">{error}</div>}
              </div>
              <div class="btn-flip-icon mt-md-0">
              <em className="trigger_n">
                                <img
                                    src={trigger}
                                    className="exchange_image"
                                    onClick={swapValues}
                                    alt="Exchange"
                                />
                            </em>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <div class="form-groupp hdd-arrow mb-0 fts bt_fp_to">

                

              <AutoComplete
                                    placeholder="To"
                                    value={destinationValue}
                                    suggestions={destinationItems}
                                    completeMethod={searchDestination}
                                    onChange={(b) => setDestinationValue(b.value)}
                                    field="label"
                                />
                                {error && <div className="error">{error}</div>}
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12">
          <div class="row gy-3 gx-lg-2 gx-3 fts">
          <div className="tab-content">
        {activeTab === 1 && <div className='full_view_own_code'> <Flight_datepickers/></div>}
        {activeTab === 0 && <div className='full_view_own_code'><Flight_datepicker_oneway/></div>}
     
      </div> 
       
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-12">
          <div class="form-groupp hdd-arrow mb-0">
    <PassngerDetails/>

          
          </div>
        </div>
        <div class="col-xl-1 col-lg-1 col-md-12">
 
        <div class="form-group mb-0 custm_cls_eng">
        <Link to="/Flight_result" ><button type="button" class="btn btn-primary full-width fw-medium"><i
                                class="fa-solid fa-magnifying-glass me-2"></i>Search</button></Link>
                    </div>

         
        </div>

      </div>
</div>
  )
}

export default Flight_auto_complete
