import React, { useState } from 'react';
import 'rsuite/dist/rsuite.min.css';
import { DateRangePicker } from 'rsuite';
import { format } from 'date-fns';

function FlightDatepickers() {
  const [dateRange, setDateRange] = useState([null, null]);

  const handleDateChange = (value) => {
    setDateRange(value);
  };

  const formatDate = (date) => {
    return date ? format(date, 'dd MMM yyyy') : '';
  };

  const today = format(new Date(), 'dd MMM yyyy');

  return (
    <div>
      <DateRangePicker
        value={dateRange}
        onChange={handleDateChange}
        placeholder={today}
        format="dd MMM yyyy"
        renderValue={(value) => {
          if (value && value.length === 2) {
            return `${formatDate(value[0])} - ${formatDate(value[1])}`;
          }
          return '';
        }}
        style={{ width: 300 }} // optional styling
      />
    </div>
  );
}

export default FlightDatepickers;
