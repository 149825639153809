import React from 'react';
import { DatePicker } from 'rsuite';

function FlightDatepickerOneway() {
  return (
    <div>
      <DatePicker 
        oneTap 
        style={{ width: 400 }} 
        format="dd MMM yyyy" 
        ranges={[]} // Disable default ranges if needed
        showOneCalendar={false} // This prop allows displaying two months side by side
        placeholder="Select a date" 
      />
    </div>
  );
}

export default FlightDatepickerOneway;
