import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Component/Homepage';
import Flight_result from './Component/Flight_result';
import Flight_travelers from './Component/Flight_travelers';



function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path='/Flight_result' element={<Flight_result/>} /> 
      <Route path='/Flight_travelers' element={<Flight_travelers/>} /> 
      
    </Routes>
  </Router> 
  );
}

export default App;
