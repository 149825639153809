import React, { useState, useRef, useEffect } from 'react'; 
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import air_1 from '../Component/images/J9.gif';
import air_2 from '../Component/images/KU.gif';
import air_3 from '../Component/images/SQ.gif';
import air_4 from '../Component/images/SV.gif';
import air_5 from '../Component/images/XY.gif';
import Price_range_flight from './Price_range_flight';
import Duration_fliter from './Duration_fliter';
import Footer from './Footer';
import Transti_time from './Transti_time';



const booknow = ['Book Now '];


function Flight_Filter() {

    const [value, setValue] = useState([38.040, 160.150]);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };
  
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    
  return (
  	<div>
    <section class="gray-simple">
    <div class="container">




      <Modal show={show} onHide={handleClose} animation={false} className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>
          <h4 class="modal-title fs-6" id="bookflightModalLabel">Flight Details </h4>
						
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div class="modal-body px-4 py-0 px-xl-3 px-lg-5">
						<div class="upper-section01 mb-3 mt-3">
							<div class="alert alert-success" role="alert">
								13% lower CO2 emissions than the average of all flights we offer for this route
							</div>
						</div>

						<div class="airLines-fullsegment">

						
							<div class="segmentDeparture-wrap">
								<div class="segmentDeparture-head mb-3">
									<h4 class="fs-5 m-0 text-capitalize">dubai to kuwait</h4>
									<p class="text-muted-2 fw-medium text-md m-0">1 Stop · 19h 46m</p>
								</div>
								<div class="segmentDeparture-block">
									<div class="segmentDeparture blockfirst">
										<ul>
											<li>
												<div class="segmenttriox">
													<h6 class="fs-6 fw-medium m-0">DXB, Dubai International Airport</h6>
													<p class="text-muted text-md m-0">Sat 7 Oct · 21:15</p>
												</div>
											</li>
											<li>
												<div class="segmenttriox">
													<h6 class="fs-6 fw-medium m-0">BAH, Bahrain International Airport</h6>
													<p class="text-muted text-md m-0">Sat 7 Oct · 22:30</p>
												</div>
											</li>
										</ul>
									</div>
									<div class="segmentDeparture-blocklast">
										<div class="d-flex align-items-start timeline-stprs">
											<div class="timeline-stprsthumb"><img src={air_2} class="img-fluid" width="40" alt=""/>
											</div>
											<div class="timeline-stprscaps ps-2">
												<p className="text-sm m-0">Air India<br/>AI812 · Economy<br/>Flight time 1h 00m <br/> Terminal:2 
												</p>

											</div>
										</div>
									</div>
								</div>
								<div class="segmentDeparture-overlay">
									<div class="css-1894l3t"><span class="text-muted"><i class="fa-regular fa-clock me-1"></i>Layover 1h
											36m</span></div>
								</div>
								<div class="segmentDeparture-block">
									<div class="segmentDeparture blockfirst">
										<ul>
											<li>
												<div class="segmenttriox">
													<h6 class="fs-6 fw-medium m-0">BAH, Bahrain International Airport</h6>
													<p class="text-muted text-md m-0">Sat 7 Oct · 21:15</p>
												</div>
											</li>
											<li>
												<div class="segmenttriox">
													<h6 class="fs-6 fw-medium m-0">KWI, kuwait International Airport</h6>
													<p class="text-muted text-md m-0">Sat 7 Oct · 22:30</p>
												</div>
											</li>
										</ul>
									</div>
									<div class="segmentDeparture-blocklast">
										<div class="d-flex align-items-start timeline-stprs">
											<div class="timeline-stprsthumb"><img src={air_2} class="img-fluid" width="40" alt=""/>
											</div>
											<div class="timeline-stprscaps ps-2">
												<p class="text-sm m-0">Air India<br/>AI812 · Economy<br/>Flight time 1h 00m <br/> Terminal:1
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>


						
							<div class="segmentDeparture-wrap mt-5">
								<div class="segmentDeparture-head mb-3">
									<h4 class="fs-5 m-0">kuwait to Dubai</h4>
									<p class="text-muted-2 fw-medium text-md m-0">Non Stop · 19h 46m</p>
								</div>
								<div class="segmentDeparture-block">
									<div class="segmentDeparture blockfirst">
										<ul>
											<li>
												<div class="segmenttriox">
													<h6 class="fs-6 fw-medium m-0">KWI, kuwait International Airport</h6>
													<p class="text-muted text-md m-0">Sat 8 Oct · 21:15</p>
												</div>
											</li>
											<li>
												<div class="segmenttriox">
													<h6 class="fs-6 fw-medium m-0">DXB, Dubai International Airport</h6>
													<p class="text-muted text-md m-0">Sat 7 Oct · 22:30</p>
												</div>
											</li>
										</ul>
									</div>
									<div class="segmentDeparture-blocklast">
										<div class="d-flex align-items-start timeline-stprs">
											<div class="timeline-stprsthumb"><img src={air_2} class="img-fluid" width="40" alt=""/>
											</div>
											<div class="timeline-stprscaps ps-2">
												<p class="text-sm m-0">IndiGo<br/>6E1012 · Economy<br/>Flight time 5h 20m <br/> Terminal:1
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

                            
						<div class="airLines-includedbaggases border-top border-bottom mt-4 py-0">
                        <Accordion>
                        <Accordion.Item eventKey="0">
        <Accordion.Header>Fare Breakdown</Accordion.Header>
        <Accordion.Body className='accordion_body_n'>
        <table class="table table-bordered"> 
    <thead>
        <tr>
            <th>Fare Description (KWD)</th>
            <th>Adult</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td><strong>Base Fare</strong></td>
            <td>22.000</td>
        </tr>
        <tr>
            <td><strong>Tax</strong></td>
            <td>95.750</td>

        </tr>
        <tr>
            <td><strong>Total</strong></td>
            <td>117.750</td>
        </tr>
        <tr>
            <td><strong>No. of Passengers</strong></td>
            <td>x 1</td>
        </tr>
        <tr>
            <td><strong>Sub Total</strong></td>
            <td>117.750</td>
        </tr>


        <tr>
            <td class="grnd_tot ttl_dicounts"><strong>Total Trip Cost</strong></td>
            <td colspan="3" class="grnd_tot_prce ttl_dicounts_right">KWD 117.750</td>
        </tr>
    </tbody>
</table>
        </Accordion.Body>
      </Accordion.Item>

                      
      <Accordion.Item eventKey="1">
        <Accordion.Header>Baggage Information</Accordion.Header>
        <Accordion.Body>
        <div class="departure-servicess mb-4">
								<h5 class="fs-6 mb-4">Dubai To kuwait</h5>
								<div class="single-includedbaggases d-flex align-items-center justify-content-between mb-3">
									<div class="includedbaggases-blc d-flex align-items-start">
										<div class="includedbaggases-icons"><i class="fa-solid fa-suitcase-rolling fs-5"></i></div>
										<div class="includedbaggases-caps ps-2">
											<p class="m-0 lh-base">1 personal item</p>
											<p class="m-0">Must go under the seat in front of you</p>
										</div>
									</div>
									
								</div>
								<div class="single-includedbaggases d-flex align-items-center justify-content-between">
									<div class="includedbaggases-blc d-flex align-items-start">
										<div class="includedbaggases-icons"><i class="fa-solid fa-briefcase fs-5"></i></div>
										<div class="includedbaggases-caps ps-2">
											<p class="m-0 lh-base">1 cabin bag</p>
											<p class="m-0">Max weight 8 kg</p>
										</div>
									</div>
									
								</div>
							</div>
							<div class="departure-servicess">
								<h5 class="fs-6 mb-4">kuwait To Dubai</h5>
								<div class="single-includedbaggases d-flex align-items-center justify-content-between mb-3">
									<div class="includedbaggases-blc d-flex align-items-start">
										<div class="includedbaggases-icons"><i class="fa-solid fa-suitcase-rolling fs-5"></i></div>
										<div class="includedbaggases-caps ps-2">
											<p class="m-0 lh-base">1 personal item</p>
											<p class="m-0">Must go under the seat in front of you</p>
										</div>
									</div>
									
								</div>
								<div class="single-includedbaggases d-flex align-items-center justify-content-between">
									<div class="includedbaggases-blc d-flex align-items-start">
										<div class="includedbaggases-icons"><i class="fa-solid fa-briefcase fs-5"></i></div>
										<div class="includedbaggases-caps ps-2">
											<p class="m-0 lh-base">1 cabin bag</p>
											<p class="m-0">Max weight 8 kg</p>
										</div>
									</div>
									
								</div>
							</div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Fare Rules</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                        </div>

						

						<div class="airLines-priceinfoy1 pt-4">
							<div class="airLines-flex d-flex align-items-center justify-content-between">
								<div class="airLinesyscb">
									<h4 class="fs-4 m-0">US$479</h4>
									<p class="text-md m-0">Total price for all travellers</p>
								</div>
								<div class="flds-ytu"><button type="submit" class="btn btn-primary btn-md fw-medium"><Link to="/Flight_travelers" >Book Now</Link></button></div>
							</div>
						</div>
					</div>
        </Modal.Body>
        
      </Modal>

        <div class="row justify-content-between gy-4 gx-xl-4 gx-lg-3 gx-md-3 gx-4">
        
      
    
            <div class="col-xl-3 col-lg-4 col-md-12">
                <div class="filter-searchBar bg-white rounded-3">
                    <div class="filter-searchBar-head border-bottom">
                        <div class="searchBar-headerBody d-flex align-items-start justify-content-between px-3 py-3">
                            <div class="searchBar-headerfirst">
                                <h6 class="fw-bold fs-5 m-0">Filters</h6>
                                <p class="text-md text-muted m-0">Showing 180 Flights</p>
                            </div>
                            <div class="searchBar-headerlast text-end">
                                <a href="#" class="text-md fw-medium text-primary active">Clear All</a>
                            </div>
                        </div>
                    </div>

                    <div class="filter-searchBar-body">

                    <div class="searchBar-single px-3 py-3 border-none">
                            <div class="searchBar-single-title d-flex mb-3">
                                <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Pricing</h6>
                            </div>
                            <div class="searchBar-single-wrap">
                           <Price_range_flight/> 
                               <div className='Price_range_flight_blnk'></div>
                            </div>
                        </div>
                     
                        <div class="searchBar-single px-3 py-3 border-bottom border-top border-zero_p">

                        	 <div class="searchBar-single-title d-flex mb-3">
                                <h6 class="sidebar-subTitle fs-6 fw-medium m-0">No. of Stops</h6>
                            </div>
                            <div class="searchBar-single-wrap">
                                <ul class="row align-items-center justify-content-between p-0 gx-3 gy-2">
                                    <li class="col-6">
                                        <input type="checkbox" class="btn-check" id="direct1"/>
                                        <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width"
                                            for="direct1">Direct</label>
                                    </li>
                                    <li class="col-6">
                                        <input type="checkbox" class="btn-check" id="1stop1"/>
                                        <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="1stop1">1
                                            Stop</label>
                                    </li>
                                    <li class="col-6">
                                        <input type="checkbox" class="btn-check" id="2stop1"/>
                                        <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="2stop1">2+
                                            Stop</label>
                                    </li>
                                </ul>
                            </div>
                            </div>
                            <div class="searchBar-single px-3 py-3 border-bottom">
                            <div class="searchBar-single-title d-flex mb-3">
                                <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Refundable Type</h6>
                            </div>
                            <div class="searchBar-single-wrap">
                                <ul class="row align-items-center justify-content-between p-0 gx-3 gy-2">
                                    <li class="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="Refundable"/>
                                            <label class="form-check-label" for="Refundable">Refundable</label>
                                        </div>
                                    </li>
                                    <li class="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="nonRefundable"/>
                                            <label class="form-check-label" for="nonRefundable">Non-Refundable</label>
                                        </div>
                                    </li>
                                    
                                </ul>
                            </div>
                            </div>

                            <div class="searchBar-single px-3 py-3 border-bottom">
                            <div class="searchBar-single-title d-flex mb-3">
                                <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Luggage</h6>
                            </div>
                            <div class="searchBar-single-wrap">
                                <ul class="row align-items-center justify-content-between p-0 gx-3 gy-2">
                                    <li class="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="0_kg"/>
                                            <label class="form-check-label" for="0_kg">0 Kilograms</label>
                                        </div>
                                    </li>

                                    <li class="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="0_p"/>
                                            <label class="form-check-label" for="0_p">0 P</label>
                                        </div>
                                    </li>

                                    <li class="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="0_1"/>
                                            <label class="form-check-label" for="0_1">1 P</label>
                                        </div>
                                    </li>

                                    <li class="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="0_2"/>
                                            <label class="form-check-label" for="0_2">2 P</label>
                                        </div>
                                    </li>

                                    <li class="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="0_3"/>
                                            <label class="form-check-label" for="0_3">3 P</label>
                                        </div>
                                    </li>

                                    <li class="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="30_kg"/>
                                            <label class="form-check-label" for="30_kg">30 Kilograms</label>
                                        </div>
                                    </li>
                                    
                                    
                                </ul>
                            </div>
                            </div>

                            <div class="searchBar-single px-3 py-3 border-none">
                            <div class="searchBar-single-title d-flex mb-3">
                                <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Duration</h6>
                            </div>
                            <div class="searchBar-single-wrap">
                                <Duration_fliter/> 
                               
                            </div>
                        </div>

                        <div class="searchBar-single px-3 py-3 border-none border-top border-zero_p">
                            <div class="searchBar-single-title d-flex mb-3">
                                <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Transit Time</h6>
                            </div>
                            <div class="searchBar-single-wrap">
                                <Transti_time/> 
                            </div>
                        </div>


                            <div class="searchBar-single px-3 py-3 border-bottom border-top border-zero_p">
                            <div class="searchBar-single-title d-flex mb-3">
                                <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Departure</h6>
                            </div>
                            <div class="searchBar-single-wrap mb-4">
                                <ul class="row align-items-center justify-content-between p-0 gx-3 gy-2">
                                    <li class="col-6">
                                        <input type="checkbox" class="btn-check" id="before6am"/>
                                        <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width"
                                            for="before6am">Before 6AM</label>
                                    </li>
                                    <li class="col-6">
                                        <input type="checkbox" class="btn-check" id="6am12pm"/>
                                        <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="6am12pm">6AM -
                                            12PM</label>
                                    </li>
                                    <li class="col-6">
                                        <input type="checkbox" class="btn-check" id="12pm6pm"/>
                                        <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="12pm6pm">12PM -
                                            6PM</label>
                                    </li>
                                    <li class="col-6">
                                        <input type="checkbox" class="btn-check" id="after6pm"/>
                                        <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="after6pm">After
                                            6PM</label>
                                    </li>
                                </ul>
                            </div>

                            <div class="searchBar-single-title d-flex mb-3">
                                <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Return</h6>
                            </div>
                            <div class="searchBar-single-wrap">
                                <ul class="row align-items-center justify-content-between p-0 gx-3 gy-2">
                                    <li class="col-6">
                                        <input type="checkbox" class="btn-check" id="before6am1"/>
                                        <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width"
                                            for="before6am1">Before 6AM</label>
                                    </li>
                                    <li class="col-6">
                                        <input type="checkbox" class="btn-check" id="6am12pm1"/>
                                        <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="6am12pm1">6AM -
                                            12PM</label>
                                    </li>
                                    <li class="col-6">
                                        <input type="checkbox" class="btn-check" id="12pm6pm1"/>
                                        <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width" for="12pm6pm1">12PM
                                            - 6PM</label>
                                    </li>
                                    <li class="col-6">
                                        <input type="checkbox" class="btn-check" id="after6pm1"/>
                                        <label class="btn btn-sm btn-secondary rounded-1 fw-medium px-4 full-width"
                                            for="after6pm1">After 6PM</label>
                                    </li>
                                </ul>
                            </div>

                        </div>


                        <div class="searchBar-single px-3 py-3 border-bottom">
                            <div class="searchBar-single-title d-flex align-items-center justify-content-between mb-3">
                                <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Preferred Airlines</h6>
                                <a href="#" class="text-md fw-medium text-muted active">Reset</a>
                            </div>
                            <div class="searchBar-single-wrap">
                                <ul class="row align-items-center justify-content-between p-0 gx-3 gy-2">
                                    <li class="col-12">
                                        <div class="form-check lg">
                                            <div class="frm-slicing d-flex align-items-center">
                                                <div class="frm-slicing-first">
                                                    <input class="form-check-input" type="checkbox" id="baggage8"/>
                                                    <label class="form-check-label" for="baggage8"></label>
                                                </div>
                                                <div
                                                    class="frm-slicing-end d-flex align-items-center justify-content-between full-width ps-1">
                                                    <div class="frms-flex d-flex align-items-center">
                                                        <div class="frm-slicing-img"><img src={air_1} class="img-fluid" width="25"
                                                                alt=""/></div>
                                                        <div class="frm-slicing-title ps-2"><span class="text-muted-2">Air India</span></div>
                                                    </div>
                                                    <div class="text-end"><span class="text-md text-muted-2 opacity-75">$390.00</span></div>
                                                </div>
                                            </div>

                                        </div>
                                    </li>
                                    <li class="col-12">
                                        <div class="form-check lg">
                                            <div class="frm-slicing d-flex align-items-center">
                                                <div class="frm-slicing-first">
                                                    <input class="form-check-input" type="checkbox" id="baggage1"/>
                                                    <label class="form-check-label" for="baggage1"></label>
                                                </div>
                                                <div
                                                    class="frm-slicing-end d-flex align-items-center justify-content-between full-width ps-1">
                                                    <div class="frms-flex d-flex align-items-center">
                                                        <div class="frm-slicing-img"><img src={air_2} class="img-fluid" width="25"
                                                                alt=""/></div>
                                                        <div class="frm-slicing-title ps-2"><span class="text-muted-2">Jal Airlines</span></div>
                                                    </div>
                                                    <div class="text-end"><span class="text-md text-muted-2 opacity-75">$310.00</span></div>
                                                </div>
                                            </div>

                                        </div>
                                    </li>
                                    <li class="col-12">
                                        <div class="form-check lg">
                                            <div class="frm-slicing d-flex align-items-center">
                                                <div class="frm-slicing-first">
                                                    <input class="form-check-input" type="checkbox" id="baggage2"/>
                                                    <label class="form-check-label" for="baggage2"></label>
                                                </div>
                                                <div
                                                    class="frm-slicing-end d-flex align-items-center justify-content-between full-width ps-1">
                                                    <div class="frms-flex d-flex align-items-center">
                                                        <div class="frm-slicing-img"><img src={air_3} class="img-fluid" width="25"
                                                                alt=""/></div>
                                                        <div class="frm-slicing-title ps-2"><span class="text-muted-2">Indigo</span></div>
                                                    </div>
                                                    <div class="text-end"><span class="text-md text-muted-2 opacity-75">$390.00</span></div>
                                                </div>
                                            </div>

                                        </div>
                                    </li>
                                    <li class="col-12">
                                        <div class="form-check lg">
                                            <div class="frm-slicing d-flex align-items-center">
                                                <div class="frm-slicing-first">
                                                    <input class="form-check-input" type="checkbox" id="baggage3"/>
                                                    <label class="form-check-label" for="baggage3"></label>
                                                </div>
                                                <div
                                                    class="frm-slicing-end d-flex align-items-center justify-content-between full-width ps-1">
                                                    <div class="frms-flex d-flex align-items-center">
                                                        <div class="frm-slicing-img"><img src={air_4} class="img-fluid" width="25"
                                                                alt=""/></div>
                                                        <div class="frm-slicing-title ps-2"><span class="text-muted-2">Air Asia</span></div>
                                                    </div>
                                                    <div class="text-end"><span class="text-md text-muted-2 opacity-75">$410.00</span></div>
                                                </div>
                                            </div>

                                        </div>
                                    </li>
                                    <li class="col-12">
                                        <div class="form-check lg">
                                            <div class="frm-slicing d-flex align-items-center">
                                                <div class="frm-slicing-first">
                                                    <input class="form-check-input" type="checkbox" id="baggage4"/>
                                                    <label class="form-check-label" for="baggage4"></label>
                                                </div>
                                                <div
                                                    class="frm-slicing-end d-flex align-items-center justify-content-between full-width ps-1">
                                                    <div class="frms-flex d-flex align-items-center">
                                                        <div class="frm-slicing-img"><img src={air_5} class="img-fluid" width="25"
                                                                alt=""/></div>
                                                        <div class="frm-slicing-title ps-2"><span class="text-muted-2">Vistara</span></div>
                                                    </div>
                                                    <div class="text-end"><span class="text-md text-muted-2 opacity-75">$370.00</span></div>
                                                </div>
                                            </div>

                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <div class="searchBar-single px-3 py-3 border-bottom">

                            <div class="searchBar-single-title d-flex mb-3">
                                <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Connecting Flights</h6>
                            </div>
                            <div class="searchBar-single-wrap">
                                <ul class="row align-items-center justify-content-between p-0 gx-3 gy-2">
                                    <li class="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="baggage"/>
                                            <label class="form-check-label" for="baggage">Gulf Air</label>
                                        </div>
                                    </li>
                                    <li class="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="inflightmeal"/>
                                            <label class="form-check-label" for="inflightmeal">Egypt Air</label>
                                        </div>
                                    </li>
                                    <li class="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="inflightenter"/>
                                            <label class="form-check-label" for="inflightenter">Turkish Airlines</label>
                                        </div>
                                    </li>
                                    <li class="col-12">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" id="flswifi"/>
                                            <label class="form-check-label" for="flswifi">Qatar Airways</label>
                                        </div>
                                    </li>
                                   
                                </ul>
                            </div>

                        </div>

                        <div class="searchBar-single px-3 py-3 border-bottom">

<div class="searchBar-single-title d-flex mb-3">
    <h6 class="sidebar-subTitle fs-6 fw-medium m-0">Connecting Aiport</h6>
</div>
<div class="searchBar-single-wrap">
    <ul class="row align-items-center justify-content-between p-0 gx-3 gy-2">
        <li class="col-12">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="ksu_aiport1"/>
                <label class="form-check-label" for="ksu_aiport1">King Khaled International Airport</label>
            </div>
        </li>
        <li class="col-12">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="inflightmea2"/>
                <label class="form-check-label" for="ksu_aiport2">Queen Alia International Airport</label>
            </div>
        </li>
        <li class="col-12">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="ksu_aiport2"/>
                <label class="form-check-label" for="ksu_aiport3">Addis Ababa Bole International Airport</label>
            </div>
        </li>
       
       
    </ul>
</div>

</div>

                      

                    </div>
                </div>
            </div>

        
            <div class="col-xl-9 col-lg-8 col-md-12">

                <div class="row align-items-center justify-content-between">
                    <div class="col-xl-4 col-lg-4 col-md-4">
                        <h5 class="fw-bold fs-6 mb-lg-0 mb-3">Showing 280 Search Results</h5>
                    </div>
                    <div class="col-xl-8 col-lg-8 col-md-12">
                        <div class="d-flex align-items-center justify-content-start justify-content-lg-end flex-wrap">
                            
                            <div class="flsx-first mt-sm-0 mt-2">
                                <ul class="nav nav-pills nav-fill p-1 small lights blukker bg-primary rounded-3 shadow-sm"
                                    id="filtersblocks" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active rounded-3" id="trending" data-bs-toggle="tab" type="button"
                                            role="tab" aria-selected="true">Our Trending</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link rounded-3" id="mostpopular" data-bs-toggle="tab" type="button"
                                            role="tab" aria-selected="false">Most Popular</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link rounded-3" id="lowprice" data-bs-toggle="tab" type="button" role="tab"
                                            aria-selected="false">Lowest Price</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center g-4 mt-2">

                   
                    <div class="col-xl-12 col-lg12 col-md-12">
                        <div class="flights-accordion">
                            <div class="flights-list-item bg-white rounded-3 p-3">
                                <div class="row gy-4 align-items-center justify-content-between">

                                    <div class="col">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="d-flex align-items-center mb-2">
                                                    <span class="label bg-light-primary text-primary me-2">Departure</span>
                                                    <span class="text-muted text-sm">26 Jun 2023</span>
                                                </div>
                                            </div>
                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="row gx-lg-5 gx-3 gy-4 align-items-center">

                                                    <div class="col-sm-auto">
                                                        <div class="d-flex align-items-center justify-content-start">
                                                            <div class="d-start fl-pic">
                                                                <img class="img-fluid" src={air_1} width="70" alt="image"/>
                                                            </div>
                                                            <div class="d-end fl-title ps-2">
                                                                <div class="text-dark fw-medium">Jazeera Airways</div>
                                                                <div class="text-sm text-muted">J9-122</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col">
                                                        <div class="row gx-3 align-items-center">
                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">07:40</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme fnt_ctme">dubai</div>
                                                            </div>

                                                            <div class="col text-center">
                                                                <div class="flightLine departure">
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>
                                                                <div class="text-muted text-sm fw-medium mt-3">Direct</div>
                                                            </div>

                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">12:20</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">kuwait</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-auto">
                                                        <div class="text-dark fw-medium">4H 40M</div>
                                                        <div class="text-muted text-sm fw-medium">2 Stop</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mt-4">
                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="d-flex align-items-center mb-2">
                                                    <span class="label bg-light-success text-success me-2">Return</span>
                                                    <span class="text-muted text-sm">26 Jun 2023</span>
                                                </div>
                                            </div>

                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="row gx-lg-5 gx-3 gy-4 align-items-center">
                                                    <div class="col-sm-auto"> 
                                                        <div class="d-flex align-items-center justify-content-start">
                                                            <div class="d-start fl-pic">
                                                                <img class="img-fluid" src={air_2} width="70" alt="image"/>
                                                            </div>
                                                            <div class="d-end fl-title ps-2">
                                                                <div class="text-dark fw-medium">Kuwait Airways</div>
                                                                <div class="text-sm text-muted">KU-79</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col">
                                                        <div class="row gx-3 align-items-center">
                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">14:10</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">kuwait</div>
                                                            </div>

                                                            <div class="col text-center">
                                                                <div class="flightLine return">
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>
                                                                <div class="text-muted text-sm fw-medium mt-3">Direct</div>
                                                            </div>

                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">19:30</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">dubai</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-auto">
                                                        <div class="text-dark fw-medium">5H 30M</div>
                                                        <div class="text-muted text-sm fw-medium">2 Stop</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-auto">
                                        <div class="d-flex items-center h-100">
                                            <div class="d-lg-block d-none border br-dashed me-4"></div>
                                            <div> 
                                                <div class="d-flex align-items-center justify-content-md-end mb-3">
                                                    <em
                                                     class="btn btn-primary btn-md fw-medium full-width"  onClick={handleShow}>
                                                        Flight Details <i class="fa-solid fa-arrow-trend-up ms-2"></i>
                                                    </em> 
                                                    
                                                </div>
                                                <div class="text-start text-md-end">
                                                    <span class="label bg-light-success text-success me-1">15% Off</span>
                                                    <div class="text-dark fs-3 fw-bold lh-base">US$934</div>
                                                    <div class="text-muted text-sm mb-2">Refundable</div>
                                                </div>

                                                <div class="flight-button-wrap">
                                                    <button class="btn btn-primary btn-md fw-medium full-width" data-bs-toggle="modal"
                                                        data-bs-target="#bookflight"><Link to="/Flight_travelers" >
                                                        {booknow}</Link><i class="fa-solid fa-arrow-trend-up ms-2"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-12 col-lg12 col-md-12">
                        <div class="d-md-flex bg-success rounded-2 align-items-center justify-content-between px-3 py-3">
                            <div class="d-md-flex align-items-center justify-content-start">
                                <div class="flx-icon-first mb-md-0 mb-3">
                                    <div class="square--60 circle bg-white"><i class="fa-solid fa-gift fs-3 text-success"></i></div>
                                </div>
                                <div class="flx-caps-first ps-2">
                                    <h6 class="fs-5 fw-medium text-light mb-0">Start Exploring The World</h6>
                                    <p class="text-light mb-0">Book FlightsEffortless and Earn $50+ for each booking with Booking.com
                                    </p>
                                </div>
                            </div>
                            <div class="flx-last text-md-end mt-md-0 mt-4"><button type="button" class="btn btn-whites fw-medium full-width text-dark px-xl-4">Get Started</button></div>
                        </div>
                    </div>

                    <div class="col-xl-12 col-lg12 col-md-12">
                        <div class="flights-accordion">
                            <div class="flights-list-item bg-white rounded-3 p-3">
                                <div class="row gy-4 align-items-center justify-content-between">

                                    <div class="col">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="d-flex align-items-center mb-2">
                                                    <span class="label bg-light-primary text-primary me-2">Departure</span>
                                                    <span class="text-muted text-sm">26 Jun 2023</span>
                                                </div>
                                            </div>
                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="row gx-lg-5 gx-3 gy-4 align-items-center">

                                                    <div class="col-sm-auto">
                                                        <div class="d-flex align-items-center justify-content-start">
                                                            <div class="d-start fl-pic">
                                                                <img class="img-fluid" src={air_5} width="70" alt="image"/>
                                                            </div>
                                                            <div class="d-end fl-title ps-2">
                                                                <div class="text-dark fw-medium">Jazeera Airways</div>
                                                                <div class="text-sm text-muted">J9-122</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col">
                                                        <div class="row gx-3 align-items-center">
                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">07:40</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">dubai</div>
                                                            </div>

                                                            <div class="col text-center">
                                                                <div class="flightLine departure">
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>
                                                                <div class="text-muted text-sm fw-medium mt-3">Direct</div>
                                                            </div>

                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">12:20</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">kuwait</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-auto">
                                                        <div class="text-dark fw-medium">4H 40M</div>
                                                        <div class="text-muted text-sm fw-medium">2 Stop</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mt-4">
                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="d-flex align-items-center mb-2">
                                                    <span class="label bg-light-success text-success me-2">Return</span>
                                                    <span class="text-muted text-sm">26 Jun 2023</span>
                                                </div>
                                            </div>

                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="row gx-lg-5 gx-3 gy-4 align-items-center">
                                                    <div class="col-sm-auto"> 
                                                        <div class="d-flex align-items-center justify-content-start">
                                                            <div class="d-start fl-pic">
                                                                <img class="img-fluid" src={air_3} width="70" alt="image"/>
                                                            </div>
                                                            <div class="d-end fl-title ps-2">
                                                                <div class="text-dark fw-medium">Kuwait Airways</div>
                                                                <div class="text-sm text-muted">KU-79</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col">
                                                        <div class="row gx-3 align-items-center">
                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">14:10</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">kuwait</div>
                                                            </div>

                                                            <div class="col text-center">
                                                                <div class="flightLine return">
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>
                                                                <div class="text-muted text-sm fw-medium mt-3">Direct</div>
                                                            </div>

                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">19:30</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">dubai</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-auto">
                                                        <div class="text-dark fw-medium">5H 30M</div>
                                                        <div class="text-muted text-sm fw-medium">2 Stop</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-auto">
                                        <div class="d-flex items-center h-100">
                                            <div class="d-lg-block d-none border br-dashed me-4"></div>
                                            <div> 
                                                <div class="d-flex align-items-center justify-content-md-end mb-3">
                                                    <em
                                                     class="btn btn-primary btn-md fw-medium full-width"  onClick={handleShow}>
                                                        Flight Details <i class="fa-solid fa-arrow-trend-up ms-2"></i>
                                                    </em> 
                                                    
                                                </div>
                                                <div class="text-start text-md-end">
                                                    <span class="label bg-light-success text-success me-1">15% Off</span>
                                                    <div class="text-dark fs-3 fw-bold lh-base">US$934</div>
                                                    <div class="text-muted text-sm mb-2">Refundable</div>
                                                </div>

                                                <div class="flight-button-wrap">
                                                    <button class="btn btn-primary btn-md fw-medium full-width" data-bs-toggle="modal"
                                                        data-bs-target="#bookflight"><Link to="/Flight_travelers" >
                                                        {booknow}</Link><i class="fa-solid fa-arrow-trend-up ms-2"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-xl-12 col-lg12 col-md-12">
                        <div class="flights-accordion">
                            <div class="flights-list-item bg-white rounded-3 p-3">
                                <div class="row gy-4 align-items-center justify-content-between">

                                    <div class="col">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="d-flex align-items-center mb-2">
                                                    <span class="label bg-light-primary text-primary me-2">Departure</span>
                                                    <span class="text-muted text-sm">26 Jun 2023</span>
                                                </div>
                                            </div>
                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="row gx-lg-5 gx-3 gy-4 align-items-center">

                                                    <div class="col-sm-auto">
                                                        <div class="d-flex align-items-center justify-content-start">
                                                            <div class="d-start fl-pic">
                                                                <img class="img-fluid" src={air_2} width="70" alt="image"/>
                                                            </div>
                                                            <div class="d-end fl-title ps-2">
                                                                <div class="text-dark fw-medium">Jazeera Airways</div>
                                                                <div class="text-sm text-muted">J9-122</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col">
                                                        <div class="row gx-3 align-items-center">
                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">07:40</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">dubai</div>
                                                            </div>

                                                            <div class="col text-center">
                                                                <div class="flightLine departure">
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>
                                                                <div class="text-muted text-sm fw-medium mt-3">Direct</div>
                                                            </div>

                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">12:20</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">kuwait</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-auto">
                                                        <div class="text-dark fw-medium">4H 40M</div>
                                                        <div class="text-muted text-sm fw-medium">2 Stop</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mt-4">
                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="d-flex align-items-center mb-2">
                                                    <span class="label bg-light-success text-success me-2">Return</span>
                                                    <span class="text-muted text-sm">26 Jun 2023</span>
                                                </div>
                                            </div>

                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="row gx-lg-5 gx-3 gy-4 align-items-center">
                                                    <div class="col-sm-auto"> 
                                                        <div class="d-flex align-items-center justify-content-start">
                                                            <div class="d-start fl-pic">
                                                                <img class="img-fluid" src={air_2} width="70" alt="image"/>
                                                            </div>
                                                            <div class="d-end fl-title ps-2">
                                                                <div class="text-dark fw-medium">Kuwait Airways</div>
                                                                <div class="text-sm text-muted">KU-79</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col">
                                                        <div class="row gx-3 align-items-center">
                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">14:10</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">kuwait</div>
                                                            </div>

                                                            <div class="col text-center">
                                                                <div class="flightLine return">
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>
                                                                <div class="text-muted text-sm fw-medium mt-3">Direct</div>
                                                            </div>

                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">19:30</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">dubai</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-auto">
                                                        <div class="text-dark fw-medium">5H 30M</div>
                                                        <div class="text-muted text-sm fw-medium">2 Stop</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-auto">
                                        <div class="d-flex items-center h-100">
                                            <div class="d-lg-block d-none border br-dashed me-4"></div>
                                            <div> 
                                                <div class="d-flex align-items-center justify-content-md-end mb-3">
                                                    <em
                                                     class="btn btn-primary btn-md fw-medium full-width"  onClick={handleShow}>
                                                        Flight Details <i class="fa-solid fa-arrow-trend-up ms-2"></i>
                                                    </em> 
                                                    
                                                </div>
                                                <div class="text-start text-md-end">
                                                    <span class="label bg-light-success text-success me-1">15% Off</span>
                                                    <div class="text-dark fs-3 fw-bold lh-base">US$934</div>
                                                    <div class="text-muted text-sm mb-2">Refundable</div>
                                                </div>

                                                <div class="flight-button-wrap">
                                                    <button class="btn btn-primary btn-md fw-medium full-width" data-bs-toggle="modal"
                                                        data-bs-target="#bookflight"><Link to="/Flight_travelers" >
                                                        {booknow}</Link><i class="fa-solid fa-arrow-trend-up ms-2"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg12 col-md-12">
                        <div class="flights-accordion">
                            <div class="flights-list-item bg-white rounded-3 p-3">
                                <div class="row gy-4 align-items-center justify-content-between">

                                    <div class="col">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="d-flex align-items-center mb-2">
                                                    <span class="label bg-light-primary text-primary me-2">Departure</span>
                                                    <span class="text-muted text-sm">26 Jun 2023</span>
                                                </div>
                                            </div>
                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="row gx-lg-5 gx-3 gy-4 align-items-center">

                                                    <div class="col-sm-auto">
                                                        <div class="d-flex align-items-center justify-content-start">
                                                            <div class="d-start fl-pic">
                                                                <img class="img-fluid" src={air_3} width="70" alt="image"/>
                                                            </div>
                                                            <div class="d-end fl-title ps-2">
                                                                <div class="text-dark fw-medium">Jazeera Airways</div>
                                                                <div class="text-sm text-muted">J9-122</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col">
                                                        <div class="row gx-3 align-items-center">
                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">07:40</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">dubai</div>
                                                            </div>

                                                            <div class="col text-center">
                                                                <div class="flightLine departure">
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>
                                                                <div class="text-muted text-sm fw-medium mt-3">Direct</div>
                                                            </div>

                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">12:20</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">kuwait</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-auto">
                                                        <div class="text-dark fw-medium">4H 40M</div>
                                                        <div class="text-muted text-sm fw-medium">2 Stop</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mt-4">
                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="d-flex align-items-center mb-2">
                                                    <span class="label bg-light-success text-success me-2">Return</span>
                                                    <span class="text-muted text-sm">26 Jun 2023</span>
                                                </div>
                                            </div>

                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="row gx-lg-5 gx-3 gy-4 align-items-center">
                                                    <div class="col-sm-auto"> 
                                                        <div class="d-flex align-items-center justify-content-start">
                                                            <div class="d-start fl-pic">
                                                                <img class="img-fluid" src={air_3} width="70" alt="image"/>
                                                            </div>
                                                            <div class="d-end fl-title ps-2">
                                                                <div class="text-dark fw-medium">Kuwait Airways</div>
                                                                <div class="text-sm text-muted">KU-79</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col">
                                                        <div class="row gx-3 align-items-center">
                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">14:10</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">kuwait</div>
                                                            </div>

                                                            <div class="col text-center">
                                                                <div class="flightLine return">
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>
                                                                <div class="text-muted text-sm fw-medium mt-3">Direct</div>
                                                            </div>

                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">19:30</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">dubai</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-auto">
                                                        <div class="text-dark fw-medium">5H 30M</div>
                                                        <div class="text-muted text-sm fw-medium">2 Stop</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-auto">
                                        <div class="d-flex items-center h-100">
                                            <div class="d-lg-block d-none border br-dashed me-4"></div>
                                            <div> 
                                                <div class="d-flex align-items-center justify-content-md-end mb-3">
                                                    <em
                                                     class="btn btn-primary btn-md fw-medium full-width"  onClick={handleShow}>
                                                        Flight Details <i class="fa-solid fa-arrow-trend-up ms-2"></i>
                                                    </em> 
                                                    
                                                </div>
                                                <div class="text-start text-md-end">
                                                    <span class="label bg-light-success text-success me-1">15% Off</span>
                                                    <div class="text-dark fs-3 fw-bold lh-base">US$934</div>
                                                    <div class="text-muted text-sm mb-2">Refundable</div>
                                                </div>

                                                <div class="flight-button-wrap">
                                                    <button class="btn btn-primary btn-md fw-medium full-width" data-bs-toggle="modal"
                                                        data-bs-target="#bookflight"><Link to="/Flight_travelers" >
                                                        {booknow}</Link><i class="fa-solid fa-arrow-trend-up ms-2"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> <div class="col-xl-12 col-lg12 col-md-12">
                        <div class="flights-accordion">
                            <div class="flights-list-item bg-white rounded-3 p-3">
                                <div class="row gy-4 align-items-center justify-content-between">

                                    <div class="col">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="d-flex align-items-center mb-2">
                                                    <span class="label bg-light-primary text-primary me-2">Departure</span>
                                                    <span class="text-muted text-sm">26 Jun 2023</span>
                                                </div>
                                            </div>
                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="row gx-lg-5 gx-3 gy-4 align-items-center">

                                                    <div class="col-sm-auto">
                                                        <div class="d-flex align-items-center justify-content-start">
                                                            <div class="d-start fl-pic">
                                                                <img class="img-fluid" src={air_4} width="70" alt="image"/>
                                                            </div>
                                                            <div class="d-end fl-title ps-2">
                                                                <div class="text-dark fw-medium">Jazeera Airways</div>
                                                                <div class="text-sm text-muted">J9-122</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col">
                                                        <div class="row gx-3 align-items-center">
                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">07:40</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">dubai</div>
                                                            </div>

                                                            <div class="col text-center">
                                                                <div class="flightLine departure">
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>
                                                                <div class="text-muted text-sm fw-medium mt-3">Direct</div>
                                                            </div>

                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">12:20</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">kuwait</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-auto">
                                                        <div class="text-dark fw-medium">4H 40M</div>
                                                        <div class="text-muted text-sm fw-medium">2 Stop</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mt-4">
                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="d-flex align-items-center mb-2">
                                                    <span class="label bg-light-success text-success me-2">Return</span>
                                                    <span class="text-muted text-sm">26 Jun 2023</span>
                                                </div>
                                            </div>

                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="row gx-lg-5 gx-3 gy-4 align-items-center">
                                                    <div class="col-sm-auto"> 
                                                        <div class="d-flex align-items-center justify-content-start">
                                                            <div class="d-start fl-pic">
                                                                <img class="img-fluid" src={air_4} width="70" alt="image"/>
                                                            </div>
                                                            <div class="d-end fl-title ps-2">
                                                                <div class="text-dark fw-medium">Kuwait Airways</div>
                                                                <div class="text-sm text-muted">KU-79</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col">
                                                        <div class="row gx-3 align-items-center">
                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">14:10</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">kuwait</div>
                                                            </div>

                                                            <div class="col text-center">
                                                                <div class="flightLine return">
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>
                                                                <div class="text-muted text-sm fw-medium mt-3">Direct</div>
                                                            </div>

                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">19:30</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">dubai</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-auto">
                                                        <div class="text-dark fw-medium">5H 30M</div>
                                                        <div class="text-muted text-sm fw-medium">2 Stop</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-auto">
                                        <div class="d-flex items-center h-100">
                                            <div class="d-lg-block d-none border br-dashed me-4"></div>
                                            <div> 
                                                <div class="d-flex align-items-center justify-content-md-end mb-3">
                                                    <em
                                                     class="btn btn-primary btn-md fw-medium full-width"  onClick={handleShow}>
                                                        Flight Details <i class="fa-solid fa-arrow-trend-up ms-2"></i>
                                                    </em> 
                                                    
                                                </div>
                                                <div class="text-start text-md-end">
                                                    <span class="label bg-light-success text-success me-1">15% Off</span>
                                                    <div class="text-dark fs-3 fw-bold lh-base">US$934</div>
                                                    <div class="text-muted text-sm mb-2">Refundable</div>
                                                </div>

                                                <div class="flight-button-wrap">
                                                    <button class="btn btn-primary btn-md fw-medium full-width" data-bs-toggle="modal"
                                                        data-bs-target="#bookflight"><Link to="/Flight_travelers" >
                                                        {booknow}</Link><i class="fa-solid fa-arrow-trend-up ms-2"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg12 col-md-12">
                        <div class="flights-accordion">
                            <div class="flights-list-item bg-white rounded-3 p-3">
                                <div class="row gy-4 align-items-center justify-content-between">

                                    <div class="col">
                                        <div class="row">
                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="d-flex align-items-center mb-2">
                                                    <span class="label bg-light-primary text-primary me-2">Departure</span>
                                                    <span class="text-muted text-sm">26 Jun 2023</span>
                                                </div>
                                            </div>
                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="row gx-lg-5 gx-3 gy-4 align-items-center">

                                                    <div class="col-sm-auto">
                                                        <div class="d-flex align-items-center justify-content-start">
                                                            <div class="d-start fl-pic">
                                                                <img class="img-fluid" src={air_5} width="70" alt="image"/>
                                                            </div>
                                                            <div class="d-end fl-title ps-2">
                                                                <div class="text-dark fw-medium">Jazeera Airways</div>
                                                                <div class="text-sm text-muted">J9-122</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col">
                                                        <div class="row gx-3 align-items-center">
                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">07:40</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">dubai</div>
                                                            </div>

                                                            <div class="col text-center">
                                                                <div class="flightLine departure">
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>
                                                                <div class="text-muted text-sm fw-medium mt-3">Direct</div>
                                                            </div>

                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">12:20</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">kuwait</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-auto">
                                                        <div class="text-dark fw-medium">4H 40M</div>
                                                        <div class="text-muted text-sm fw-medium">2 Stop</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row mt-4">
                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="d-flex align-items-center mb-2">
                                                    <span class="label bg-light-success text-success me-2">Return</span>
                                                    <span class="text-muted text-sm">26 Jun 2023</span>
                                                </div>
                                            </div>

                                            <div class="col-xl-12 col-lg-12 col-md-12">
                                                <div class="row gx-lg-5 gx-3 gy-4 align-items-center">
                                                    <div class="col-sm-auto"> 
                                                        <div class="d-flex align-items-center justify-content-start">
                                                            <div class="d-start fl-pic">
                                                                <img class="img-fluid" src={air_5} width="70" alt="image"/>
                                                            </div>
                                                            <div class="d-end fl-title ps-2">
                                                                <div class="text-dark fw-medium">Kuwait Airways</div>
                                                                <div class="text-sm text-muted">KU-79</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col">
                                                        <div class="row gx-3 align-items-center">
                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">14:10</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">kuwait</div>
                                                            </div>

                                                            <div class="col text-center">
                                                                <div class="flightLine return">
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>
                                                                <div class="text-muted text-sm fw-medium mt-3">Direct</div>
                                                            </div>

                                                            <div class="col-auto">
                                                                <div class="text-dark fw-bold font_customs">19:30</div>
                                                                <div class="text-muted text-sm fw-medium text-capitalize fnt_ctme">dubai</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-auto">
                                                        <div class="text-dark fw-medium">5H 30M</div>
                                                        <div class="text-muted text-sm fw-medium">2 Stop</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-auto">
                                        <div class="d-flex items-center h-100">
                                            <div class="d-lg-block d-none border br-dashed me-4"></div>
                                            <div> 
                                                <div class="d-flex align-items-center justify-content-md-end mb-3">
                                                    <em
                                                     class="btn btn-primary btn-md fw-medium full-width"  onClick={handleShow}>
                                                        Flight Details <i class="fa-solid fa-arrow-trend-up ms-2"></i>
                                                    </em> 
                                                    
                                                </div>
                                                <div class="text-start text-md-end">
                                                    <span class="label bg-light-success text-success me-1">15% Off</span>
                                                    <div class="text-dark fs-3 fw-bold lh-base">US$934</div>
                                                    <div class="text-muted text-sm mb-2">Refundable</div>
                                                </div>

                                                <div class="flight-button-wrap">
                                                    <button class="btn btn-primary btn-md fw-medium full-width" data-bs-toggle="modal"
                                                        data-bs-target="#bookflight"><Link to="/Flight_travelers" >
                                                        {booknow}</Link><i class="fa-solid fa-arrow-trend-up ms-2"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                   

                </div>
            </div>

        </div>
    </div>
    </section>
    <Footer/>
 </div>
  )
}

export default Flight_Filter
