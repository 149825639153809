import React, { useState, useEffect, useRef } from 'react';

function PassngerDetails() {
  const [selectedValue, setSelectedValue] = useState('Economy'); // Default value for <input>
  const [activeIndex, setActiveIndex] = useState(0); // Default active index for <li>
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility

  const [adultCount, setAdultCount] = useState(1); // State for adult count
  const [childCount, setChildCount] = useState(0); // State for child count
  const [infantCount, setInfantCount] = useState(0); // State for infant count

  const dropdownRef = useRef(null); // Ref to keep track of the dropdown element

  const options = ['Economy', 'P.Economy', 'Business', 'First'];

  // Update the input value based on the selected option and passenger counts
  useEffect(() => {
    const passengerText = `${adultCount} Adult${adultCount > 1 ? 's' : ''}, ${childCount} Child${childCount !== 1 ? 'ren' : ''}, ${infantCount} Infant${infantCount > 1 ? 's' : ''}`;
    setSelectedValue(`${options[activeIndex]} - ${passengerText}`);
  }, [activeIndex, adultCount, childCount, infantCount]);

  const handleClick = (value, index) => {
    setActiveIndex(index); // Update the active index for styling
    setIsDropdownOpen(false); // Close dropdown after selection
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown visibility
  };

  // Handle adult count increase
  const increaseAdults = () => setAdultCount(adultCount + 1);
  // Handle adult count decrease
  const decreaseAdults = () => {
    if (adultCount > 1) setAdultCount(adultCount - 1);
  };

  // Handle child count increase
  const increaseChildren = () => setChildCount(childCount + 1);
  // Handle child count decrease
  const decreaseChildren = () => {
    if (childCount > 0) setChildCount(childCount - 1);
  };

  // Handle infant count increase
  const increaseInfants = () => setInfantCount(infantCount + 1);
  // Handle infant count decrease
  const decreaseInfants = () => {
    if (infantCount > 0) setInfantCount(infantCount - 1);
  };

  // Effect to handle clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Close dropdown if clicked outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className='passngerDetails_head_n fts' ref={dropdownRef}>
      <input
        value={selectedValue}
        onClick={toggleDropdown} // Toggle dropdown on input click
        readOnly // Make input read-only to prevent manual typing
      />
      {isDropdownOpen && ( // Conditional rendering of the dropdown
        <>
          <div className="Economy_flx_wrp">
            
        
          <div className='pax_count_work'>
            {/* Adult Count */}
            <div className='pax_count_wraper'>
              <span>Adults</span>
              <div className='pax_count_multiple'>
                <button onClick={decreaseAdults}>-</button>
                <input value={adultCount} readOnly />
                <button onClick={increaseAdults}>+</button>
              </div>
            </div>
            {/* Child Count */}
            <div className='pax_count_wraper'>
              <span>Children</span>
              <div className='pax_count_multiple'>
                <button onClick={decreaseChildren}>-</button>
                <input value={childCount} readOnly />
                <button onClick={increaseChildren}>+</button>
              </div>
            </div>
            {/* Infant Count */}
            <div className='pax_count_wraper'>
              <span>Infants</span>
              <div className='pax_count_multiple'>
                <button onClick={decreaseInfants}>-</button>
                <input value={infantCount} readOnly />
                <button onClick={increaseInfants}>+</button>
              </div>
            </div>
          </div>
          <ul>
              {options.map((option, index) => (
                <li
                  key={index}
                  onClick={() => handleClick(option, index)}
                  style={{
                    cursor: 'pointer',
                    background: activeIndex === index ? '#dadfe6' : 'white',
                    fontWeight: activeIndex === index ? 'bold' : 'normal',
                  }}
                >
                  {option}
                </li>
              ))}
            </ul>
          </div> 
        </>
      )}
    </div>
  );
}

export default PassngerDetails;
