import React, { useState } from 'react';
import ReactSlider from 'react-slider';

const Transti_time = () => {
  const [minValue, setMinValue] = useState(1.00);
  const [maxValue, setMaxValue] = useState(23.00);

  const handleSliderChange = (values) => {
    setMinValue(values[0]);
    setMaxValue(values[1]);
  };



  return (
    <div className="price-range-slider">
      <h3>{minValue} Hours - {maxValue} Hours</h3>
      <ReactSlider
        className="horizontal-slider"
        thumbClassName="thumb"
        trackClassName="track"
        defaultValue={[minValue, maxValue]}
        min={1}
        max={23}
        ariaLabel={['Lower thumb', 'Upper thumb']}
        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
        onChange={handleSliderChange}
      />
    </div>
  );
};

export default Transti_time;

