import React from 'react';
import footer_logo from '../Component/images/footer_logo.png'; 
import payemnt_methode from '../Component/images/payment.png';
import our_partner1 from '../Component/images/gobigo.png';
import our_partner2 from '../Component/images/trip.png';
import our_partner3 from '../Component/images/mytrup.png';

function Footer() { 
  return (
    <div>
          <footer class="footer skin-light-footer">
      <div>
        <div class="container">
          <div class="row">

            <div class="col-lg-3 col-md-4">
              <div class="footer-widget">
                <div class="d-flex align-items-start flex-column mb-3">
                  <div class="d-inline-block"><img src={footer_logo} class="img-fluid rp" width="160" 
                      alt="Footer Logo" />
                  </div>
                </div>
                <div class="footer-add pe-xl-3">
                  <p>We make your dream more beautiful & enjoyful with lots of happiness.</p>
                </div>
                <div class="foot-socials">
                  <ul>
                    <li><a href="#"><i class="fa-brands fa-facebook"></i></a></li>
                    <li><a href="#"><i class="fa-brands fa-linkedin"></i></a></li>
                    <li><a href="#"><i class="fa-brands fa-google-plus"></i></a></li>
                    <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                    <li><a href="#"><i class="fa-brands fa-dribbble"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-4">
              <div class="footer-widget">
                <h4 class="widget-title">Quick Links</h4>
                <ul class="footer-menu">
                  <li><a href="JavaScript:Void(0);">Flight</a></li>
                  <li><a href="JavaScript:Void(0);">Hotel</a></li>
                  </ul>
              </div>
            </div>

            <div class="col-lg-2 col-md-4">
              <div class="footer-widget">
                <h4 class="widget-title">Our Resources</h4>
                <ul class="footer-menu">
                  <li><a href="JavaScript:Void(0);">Free Business tools</a></li>
                  <li><a href="JavaScript:Void(0);">Affiliate Program</a></li>
                  <li><a href="JavaScript:Void(0);">Success Stories</a></li>
                  <li><a href="JavaScript:Void(0);">Upwork Reviews</a></li>
                  <li><a href="JavaScript:Void(0);">Resources</a></li>
                  <li><a href="JavaScript:Void(0);">Help & Support</a></li>
                </ul>
              </div>
            </div>

            <div class="col-lg-2 col-md-6">
              <div class="footer-widget">
                <h4 class="widget-title">The Company</h4>
                <ul class="footer-menu">
                  <li><a href="JavaScript:Void(0);">About Us</a></li>
                  <li><a href="JavaScript:Void(0);">Leadership</a></li>
                  <li><a href="JavaScript:Void(0);">Contact Us</a></li>
                  <li><a href="JavaScript:Void(0);">Investor Relations</a></li>
                  <li><a href="JavaScript:Void(0);">Trust, Safety & Security</a></li>
                </ul>
              </div>
            </div>

            <div class="col-lg-3 col-md-6">
              <div class="footer-widget">
                <h4 class="widget-title">Payment Methods</h4>
                <div class="pmt-wrap">
                  <img src={payemnt_methode} class="img-fluid" alt="" /> 
                </div>
                <div class="our-prtwrap mt-4">
                  <div class="prtn-title">
                    <h4 class="widget-title">Our Partners</h4>
                  </div>
                  <div class="prtn-thumbs d-flex align-items-center justify-content-start">
                    <div class="pmt-wrap pe-4">
                      <img src={our_partner3} class="img-fluid" alt=""/>
                    </div>
                    <div class="pmt-wrap pe-4">
                      <img src={our_partner2} class="img-fluid" alt=""/>
                    </div>
                    <div class="pmt-wrap pe-4">
                      <img src={our_partner1} class="img-fluid" alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="footer-bottom border-top">
        <div class="container">
          <div class="row align-items-center justify-content-between">

            <div class="col-xl-6 col-lg-6 col-md-6">
              <p class="mb-0">© 2024 Provab Technosoft pvt. ltd</p>
            </div>

            <div class="col-xl-6 col-lg-6 col-md-6">
              <ul class="p-0 d-flex justify-content-start justify-content-md-end text-start text-md-end m-0">
                <li><a href="#">Terms of services</a></li>
                <li class="ms-3"><a href="#">Privacy Policies</a></li>
                <li class="ms-3"><a href="#">Cookies</a></li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </footer>
    </div>
  )
}

export default Footer
