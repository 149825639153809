import React from 'react';
import Flight_auto_complete from './Flight_auto_complete';

function Flight_engine() {
  return (
    <div class="tab-pane" id="flights">
    <div class="row gx-lg-2 g-3">

        <Flight_auto_complete/> 

     

      </div>
</div>
  )
}

export default Flight_engine
