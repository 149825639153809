import React from 'react';

const poplr_routs =['Explore Popular Routes'];
const form = ["from"];
const currncy = ["kwd"];
const poplr_p =['Cicero famously orated against his political opponent Lucius Sergius Catilina.'];

const Popularroutes_data = [
    { firstcity: 'New York', secondcity: 'Los Angeles', tripbase: 'Round-trip', days:"3 day", price:"492", Featured: "Featured",  images: require ('../Component/images/tr-1.jpg') },

    { firstcity: 'San Diego', secondcity: 'San Jose', tripbase: 'Round-trip', days:"3 day", price:"509",   images: require ('../Component/images/tr-2.jpg') },
    { firstcity: 'Dallas', secondcity: 'Philadelphia', tripbase: 'Round-trip', days:"3 day", price:"600",   images: require ('../Component/images/tr-3.jpg') },

    { firstcity: 'Denver', secondcity: 'Nashville', tripbase: 'Round-trip', days:"3 day", price:"415",   images: require ('../Component/images/tr-4.jpg') },

    { firstcity: 'Chicago', secondcity: 'San Francisco', tripbase: 'Round-trip', days:"3 day", price:"400",   images: require ('../Component/images/tr-5.jpg') },

    { firstcity: 'Houston', secondcity: 'Los Angeles', tripbase: 'Round-trip', days:"3 day", price:"380",   images: require ('../Component/images/tr-6.jpg') },
    { firstcity: 'San Antonio', secondcity: 'Columbus', tripbase: 'Round-trip', days:"3 day", price:"400",   images: require ('../Component/images/tr-7.jpg') },
    { firstcity: 'Los Angeles', secondcity: 'Canada', tripbase: 'Round-trip', days:"3 day", price:"250",   images: require ('../Component/images/tr-9.jpg') },
    
    
  ];
  
function PopularRoutes() {
  return (
    <div>
          <section class="gray-simple">
      <div class="container">

        <div class="row align-items-center justify-content-center">
          <div class="col-xl-8 col-lg-9 col-md-11 col-sm-12">
            <div class="secHeading-wrap text-center mb-5">
              <h2>{poplr_routs}</h2>
              <p>{poplr_p}</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center gy-4 gx-3">

        {Popularroutes_data.map((item, index) => (

            

          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6"> 
            <div class="pop-touritem">
              <a href="#" class="card rounded-3 shadow-wrap h-100 m-0">
                <div class="flight-thumb-wrapper">
                 
                  <div class="popFlights-item-overHidden">
                    <img src={item.images} class="img-fluid" alt="" />
                  </div>
                </div>
                <div class="touritem-middle position-relative p-3">
                  <div class="touritem-flexxer">
                    <h4 class="city fs-6 m-0 fw-bold">
                      <span>{item.firstcity}</span>
                      <span class="svg-icon svg-icon-muted svg-icon-2hx px-1">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M17.4 7H4C3.4 7 3 7.4 3 8C3 8.6 3.4 9 4 9H17.4V7ZM6.60001 15H20C20.6 15 21 15.4 21 16C21 16.6 20.6 17 20 17H6.60001V15Z"
                            fill="currentColor" />
                          <path opacity="0.3"
                            d="M17.4 3V13L21.7 8.70001C22.1 8.30001 22.1 7.69999 21.7 7.29999L17.4 3ZM6.6 11V21L2.3 16.7C1.9 16.3 1.9 15.7 2.3 15.3L6.6 11Z"
                            fill="currentColor" />
                        </svg>
                      </span>
                      <span>{item.secondcity}</span>
                    </h4>
                    <p class="detail ellipsis-container">
                      <span class="ellipsis-item__normal">{item.tripbase}</span>
                      <span class="separate ellipsis-item__normal"></span>
                      <span class="ellipsis-item">{item.days}</span>
                    </p>
                  </div>
                  <div class="flight-foots">
                    <h5 class="fs-5 low-price m-0"><span class="tag-span">{form}</span> <span class="price">{currncy} {item.price}</span>
                    </h5>
                  </div>
                </div>
              </a>
            </div>
          </div>

))}  
       
 
        </div>

        <div class="row align-items-center justify-content-center">
          <div class="col-xl-12 col-lg-12 col-md-12">
            <div class="text-center position-relative mt-5">
              <button type="button" class="btn btn-light-primary fw-medium px-5">Explore More<i
                  class="fa-solid fa-arrow-trend-up ms-2"></i></button>
            </div>
          </div>
        </div>

      </div>
    </section> 
    </div>
  )
}

export default PopularRoutes
