import React, { useEffect, useState } from 'react'; 
import footer_logo from '../Component/images/logo-light.png'; 
import flag_in from '../Component/images/flag.png';   



import us from '../Component/images/flag/unitedstates.png';
import unitedkingdom from '../Component/images/flag/unitedkingdom.png';
import indian from '../Component/images/flag/flag.png';
import belgium from '../Component/images/flag/belgium.png';
import brazil from '../Component/images/flag/brazil.png';
import china from '../Component/images/flag/china.png';
import vietnam from '../Component/images/flag/vietnam.png';
import turkey from '../Component/images/flag/turkey.png';
import spain from '../Component/images/flag/spain.png';
import japan from '../Component/images/flag/japan.png';
import Bahraini from '../Component/images/flag/flag.png';
import portugal from '../Component/images/flag/portugal.png';
import italy from '../Component/images/flag/italy.png';
import germany from '../Component/images/flag/germany.png';
import france from '../Component/images/flag/france.png';
import european from '../Component/images/flag/european.png';

import landing_logo from '../Component/images/landing_logo.png';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'


function Header() {

	const [showCurrncy, setShowCurrncy] = useState(false);
	const handleCloseCurrncy = () => setShowCurrncy(false);
	const handleShowCurrncy = () => setShowCurrncy(true);

	const [showCountry, setShowCountry] = useState(false);
	const handleCloseCountry = () => setShowCountry(false);
	const handleShowCountry = () => setShowCountry(true);

	const [showlogin, setShowlogin] = useState(false);
	const handleCloselogin = () => setShowlogin(false);
	const handleShowlogin = () => setShowlogin(true);




	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
	  const handleScroll = () => {
		if (window.scrollY > 50) {
		  setScrolled(true);
		} else {
		  setScrolled(false);
		}
	  };
  
	  window.addEventListener('scroll', handleScroll);
  
	  return () => {
		window.removeEventListener('scroll', handleScroll);
	  };
	}, []);
  return (
    <div>
       <div className={`header header-transparent theme ${scrolled ? 'theme header-fixed' : ''}`}>
	   <div class="container">
				<nav id="navigation" class="navigation navigation-landscape">
					<div class="nav-header">
						<a class="nav-brand static-show" href="/"><img src={landing_logo} class="logo" alt="" /></a>
						<a class="nav-brand mob-show" href="#"><img src={footer_logo} class="logo" alt="" /></a>
						<div class="nav-toggle"></div>
						<div class="mobile_nav">
							<ul>
								<li class="currencyDropdown me-2">
									<a  class="nav-link">
										<span
											class="fw-medium">INR</span></a>
								</li>
								<li class="languageDropdown me-2">
									<a href="#" class="nav-link" data-bs-toggle="modal" data-bs-target="#countryModal"><img
											src={flag_in} class="img-fluid" width="17" alt="Country" /></a>
								</li>
								<li>
									<a href="#" class="bg-light-primary text-primary rounded" data-bs-toggle="modal"
										data-bs-target="#login"><i class="fa-regular fa-circle-user fs-6"></i></a>
								</li>
							</ul>
						</div>
					</div>
					<div class="nav-menus-wrapper">
						<ul class="nav-menu">

							<li><a href="#">Home<span class="submenu-indicator"></span></a>
								
							</li>

							<li><a href="#">Flight<span class="submenu-indicator"></span></a></li>
							<li><a href="#">Hotel<span class="submenu-indicator"></span></a></li>
								
									


						</ul>

						<ul class="nav-menu nav-menu-social align-to-right">
							<li class="currencyDropdown me-2">
								<a href="#" class="nav-link" onClick={handleShowCurrncy}><span
										class="fw-medium">INR </span></a>
							</li>
							<li class="languageDropdown me-2">
								<a href="#" class="nav-link" onClick={handleShowCountry}>
									<img 
										src={flag_in} class="img-fluid" width="17" alt="Country" /></a>
							</li>
							<li class="list-buttons light">
								<a href="#" onClick={handleShowlogin}><i
										class="fa-regular fa-circle-user fs-6 me-2"></i>Sign In / Register</a>
							</li>
						</ul>
					</div>
				</nav>
			</div> 
    </div> 
	<Modal show={showCurrncy} onHide={handleCloseCurrncy} animation={false} className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>
          <h4 class="modal-title fs-6" id="currenyModalLabel">Select Your Currency</h4>
						
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
		<div class="allCurrencylist">

<div class="suggestedCurrencylist-wrap mb-4">
  <div class="d-inline-block mb-0 ps-3">
	<h5 class="fs-6 fw-bold">Suggested Currency For you</h5>
  </div>
  <div class="suggestedCurrencylists">
	<ul
	  class="row align-items-center justify-content-start row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-2 gy-2 gx-3 m-0 p-0">
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">United State Dollar</div>
		  <div class="text-muted-2 text-md text-uppercase">USD</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Pound Sterling</div>
		  <div class="text-muted-2 text-md text-uppercase">GBP</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency active" href="#">
		  <div class="text-dark text-md fw-medium">Indian Rupees</div>
		  <div class="text-muted-2 text-md text-uppercase">Inr</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Euro</div>
		  <div class="text-muted-2 text-md text-uppercase">EUR</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Australian Dollar</div>
		  <div class="text-muted-2 text-md text-uppercase">aud</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Thai Baht</div>
		  <div class="text-muted-2 text-md text-uppercase">thb</div>
		</a>
	  </li>
	</ul>
  </div>
</div>

<div class="suggestedCurrencylist-wrap">
  <div class="d-inline-block mb-0 ps-3">
	<h5 class="fs-6 fw-bold">All Currencies</h5>
  </div>
  <div class="suggestedCurrencylists">
	<ul
	  class="row align-items-center justify-content-start row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-2 gy-2 gx-3 m-0 p-0">
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">United State Dollar</div>
		  <div class="text-muted-2 text-md text-uppercase">USD</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Property currency</div>
		  <div class="text-muted-2 text-md text-uppercase">GBP</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Argentine Peso</div>
		  <div class="text-muted-2 text-md text-uppercase">EUR</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Azerbaijani Manat</div>
		  <div class="text-muted-2 text-md text-uppercase">Inr</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Australian Dollar</div>
		  <div class="text-muted-2 text-md text-uppercase">aud</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Bahraini Dinar</div>
		  <div class="text-muted-2 text-md text-uppercase">thb</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Brazilian Real</div>
		  <div class="text-muted-2 text-md text-uppercase">USD</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Bulgarian Lev</div>
		  <div class="text-muted-2 text-md text-uppercase">GBP</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Canadian Dollar</div>
		  <div class="text-muted-2 text-md text-uppercase">EUR</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Chilean Peso</div>
		  <div class="text-muted-2 text-md text-uppercase">Inr</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Colombian Peso</div>
		  <div class="text-muted-2 text-md text-uppercase">aud</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Danish Krone</div>
		  <div class="text-muted-2 text-md text-uppercase">thb</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Egyptian Pound</div>
		  <div class="text-muted-2 text-md text-uppercase">USD</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Hungarian Forint</div>
		  <div class="text-muted-2 text-md text-uppercase">GBP</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Japanese Yen</div>
		  <div class="text-muted-2 text-md text-uppercase">EUR</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Jordanian Dinar</div>
		  <div class="text-muted-2 text-md text-uppercase">Inr</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Kuwaiti Dinar</div>
		  <div class="text-muted-2 text-md text-uppercase">aud</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Malaysian Ringgit</div>
		  <div class="text-muted-2 text-md text-uppercase">thb</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCurrency" href="#">
		  <div class="text-dark text-md fw-medium">Singapore Dollar</div>
		  <div class="text-muted-2 text-md text-uppercase">thb</div>
		</a>
	  </li>
	</ul>
  </div>
</div>

</div>
        </Modal.Body>
        
      </Modal>


	  <Modal show={showCountry} onHide={handleCloseCountry} animation={false} className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>
		  <h4 class="modal-title fs-6" id="countryModalLabel">Select Your Country</h4>
						
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
		<div class="allCountrieslist">

<div class="suggestedCurrencylist-wrap mb-4">
  <div class="d-inline-block mb-0 ps-3">
	<h5 class="fs-6 fw-bold">Suggested Countries For you</h5>
  </div>
  <div class="suggestedCurrencylists">
	<ul
	  class="row align-items-center justify-content-start row-cols-xl-4 row-cols-lg-3 row-cols-2 gy-2 gx-3 m-0 p-0">
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={us} class="img-fluid circle"
			  width="35" alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">United State Dollar</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={unitedkingdom} class="img-fluid circle"
			  width="35" alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Pound Sterling</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry active" href="#">
		  <div class="d-inline-block"><img src= {indian} class="img-fluid circle" width="35"
			  alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Indian Rupees</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src= {belgium} class="img-fluid circle" width="35"
			  alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Euro</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={brazil} class="img-fluid circle" width="35"
			  alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Australian Dollar</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={china} class="img-fluid circle" width="35"
			  alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Thai Baht</div>
		</a>
	  </li>
	</ul>
  </div>
</div>

<div class="suggestedCurrencylist-wrap">
  <div class="d-inline-block mb-0 ps-3">
	<h5 class="fs-6 fw-bold">All Countries</h5>
  </div>
  <div class="suggestedCurrencylists">
	<ul
	  class="row align-items-center justify-content-start row-cols-xl-4 row-cols-lg-3 row-cols-2 gy-2 gx-3 m-0 p-0">
	  <li class="col">
		  <a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={us} class="img-fluid circle"
			  width="35" alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">United State Dollar</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={vietnam} class="img-fluid circle" width="35"
			  alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Property currency</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={turkey}  class="img-fluid circle" width="35"
			  alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Argentine Peso</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={spain}  class="img-fluid circle" width="35"
			  alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Azerbaijani Manat</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={japan}  class="img-fluid circle" width="35"
			  alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Australian Dollar</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={Bahraini}  class="img-fluid circle" width="35"
			  alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Bahraini Dinar</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={portugal}  class="img-fluid circle"
			  width="35" alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Brazilian Real</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={italy}  class="img-fluid circle" width="35"
			  alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Bulgarian Lev</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={germany}  class="img-fluid circle" width="35"
			  alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Canadian Dollar</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={france}  class="img-fluid circle" width="35"
			  alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Chilean Peso</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={european}  class="img-fluid circle"
			  width="35" alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Colombian Peso</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={china}  class="img-fluid circle" width="35"
			  alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Danish Krone</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={brazil}  class="img-fluid circle" width="35"
			  alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Egyptian Pound</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={belgium}  class="img-fluid circle" width="35"
			  alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Hungarian Forint</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={turkey}  class="img-fluid circle" width="35"
			  alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Japanese Yen</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img  src={spain}  class="img-fluid circle" width="35"
			  alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Jordanian Dinar</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={germany}  class="img-fluid circle" width="35"
			  alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Kuwaiti Dinar</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={france}  class="img-fluid circle" width="35"
			  alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Malaysian Ringgit</div>
		</a>
	  </li>
	  <li class="col">
		<a class="selectCountry" href="#">
		  <div class="d-inline-block"><img src={brazil}  class="img-fluid circle" width="35"
			  alt=""/></div>
		  <div class="text-dark text-md fw-medium ps-2">Singapore Dollar</div>
		</a>
	  </li>
	</ul>
  </div>
</div>

</div>
        </Modal.Body>
        
      </Modal>



	  <Modal show={showlogin} onHide={handleCloselogin} animation={false} className=''>
        <Modal.Header closeButton>
          <Modal.Title>
		  <h4 class="modal-title fs-6">Sign In / Register</h4>
						
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
		<div class="modal-login-form py-4 px-md-3 px-0">
							<form>

								<div class="form-floating mb-4">
									<input type="email" class="form-control" placeholder="name@example.com"/>
									<label>User Name</label>
								</div>
								<div class="form-floating mb-4">
									<input type="password" class="form-control" placeholder="Password"/>
									<label>Password</label>
								</div>

								<div class="form-group">
									<button type="submit" class="btn btn-primary full-width font--bold btn-lg">Log In</button>
								</div>

								<div class="modal-flex-item d-flex align-items-center justify-content-between mb-3">
									<div class="modal-flex-first">
										<div class="form-check form-check-inline">
											<input class="form-check-input" type="checkbox" id="savepassword" value="option1"/>
											<label class="form-check-label" for="savepassword">Save Password</label>
										</div>
									</div>
									<div class="modal-flex-last">
										<a href="#" class="text-primary fw-medium">Forget Password?</a>
									</div>
								</div>
							</form>
						</div>
        </Modal.Body>
        
      </Modal>

    </div>
  )
}

export default Header
