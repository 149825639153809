import React from 'react'; 
const why_choose_json_data = [
    { id: 1, name: 'Easy Booking', paraghp: 'Cicero famously orated against his political opponent Lucius Sergius Catilina. Occasionally the first Oration against Catiline.' },
    { id: 2, name: 'Best Destinations', paraghp: 'Cicero famously orated against his political opponent Lucius Sergius Catilina. Occasionally the first Oration against Catiline.' },
    { id: 2, name: 'Travel Guides', paraghp: 'Cicero famously orated against his political opponent Lucius Sergius Catilina. Occasionally the first Oration against Catiline.' },
    { id: 2, name: 'Friendly Support', paraghp: 'Cicero famously orated against his political opponent Lucius Sergius Catilina. Occasionally the first Oration against Catiline.' },
    // ... more data objects
  ];
 

function Why_choose() {
  return (
    <section class="border-bottom">
      <div class="container">
        <div class="row align-items-center justify-content-between g-4">
        {why_choose_json_data.map((item, index) => (
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-5" key={item.id}>
            <div class="featuresBox-wrap">
              <div class="featuresBox-icons mb-3">
                <i class="fa-solid fa-sack-dollar fs-1 text-primary"></i>
              </div>


           
    
     
              <div class="featuresBox-captions">
                <h4 class="fw-bold fs-5 lh-base mb-0">{item.name}</h4>
                <p class="m-0">{item.paraghp}</p>
              </div>
            </div>
          </div>
           ))} 
        </div>
        
      </div>
    </section>
  )
}

export default Why_choose
