import React from 'react';
const popular_head = ['Explore Popular Destination'];
const popular_praghp = ['Cicero famously orated against his political opponent Lucius Sergius Catilina.'];
const PopularDestination_data = [
    { name: 'New York', hotel: '5 Hotels', Destinations: '10 Destinations', images: require ('../Component/images/c-1.png') },
    { name: 'Las Vegas', hotel: '14 Hotels', Destinations: '6 Destinations', images: require ('../Component/images/c-2.png') },
    { name: 'San Antonio', hotel: '16 Hotels', Destinations: '9 Destinations', images: require ('../Component/images/c-3.png') },
    { name: 'Houston', hotel: '18 Hotels', Destinations: '7 Destinations', images: require ('../Component/images/c-4.png') },
    { name: 'San Francisco', hotel: '12 Hotels', Destinations: '4 Destinations', images: require ('../Component/images/c-5.png') },
    { name: 'Nashville', hotel: '7 Hotels', Destinations: '16 Destinations', images: require ('../Component/images/c-6.png') },
    { name: 'Philadelphia', hotel: '10 Hotels', Destinations: '14 Destinations', images: require ('../Component/images/c-7.png') },
    { name: 'San Diego', hotel: '32 Hotels', Destinations: ' 12 Destinations', images: require ('../Component/images/c-8.png') },
  ];  

function PopularDestination() {
  return (
    <section>
      <div class="container">

        <div class="row align-items-center justify-content-center">
          <div class="col-xl-8 col-lg-9 col-md-11 col-sm-12">
            <div class="secHeading-wrap text-center mb-5">
              <h2>{popular_head}</h2>
              <p>{popular_praghp}</p>
            </div>
          </div>
        </div>

        <div class="row justify-content-center gy-4 gx-3">
        {PopularDestination_data.map((item, index) => (
          <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6">
            <div class="destination-blocks bg-white p-2 rounded border br-dashed h-100">
              <div class="destination-blocks-pics p-1">
                <a href="#"><img src={item.images} class="img-fluid rounded" alt={item.name}/></a>
              </div>
              <div class="destination-blocks-captions">
                <div class="touritem-flexxer text-center p-3">
                  <h4 class="city fs-5 m-0 fw-bold">
                    <span>{item.name}</span>
                  </h4>
                  <p class="detail ellipsis-container m-0">
                    <span class="ellipsis-item__normal">{item.Destinations}</span> 
                    <span class="separate ellipsis-item__normal"></span>
                    <span class="ellipsis-item">{item.hotel}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
             ))} 
         


         

          

         

          

        </div>
      </div>
    </section>
  )
}

export default PopularDestination
