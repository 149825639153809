import React from 'react'
import Modify_flight from './Modify_flight'
import Flight_Filter from './Flight_Filter'
import InnerPageHead from './InnerPageHead'
import Header from './Header'


function Flight_result() {
  return (
    <div>
<InnerPageHead/>   

    <Modify_flight/> 
   
    <Flight_Filter/> 


    </div>
  )
}

export default Flight_result
