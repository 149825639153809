import React from 'react'

function Newslatter() {
  return (
    <div>
        <div class="py-5 bg-primary">
      <div class="container">
        <div class="row align-items-center justify-content-between">

          <div class="col-xl-4 col-lg-4 col-md-6">
            <h4 class="text-light fw-bold lh-base m-0">Join our Newsletter To Keep Up To Date With Us!</h4>
          </div>

          <div class="col-xl-5 col-lg-5 col-md-6">
            <div class="newsletter-forms mt-md-0 mt-4">
              <form>
                <div class="row align-items-center justify-content-between bg-white rounded-3 p-2 gx-0">

                  <div class="col-xl-9 col-lg-8 col-md-8">
                    <div class="form-group m-0">
                      <input type="email" class="form-control bold ps-1 border-0" placeholder="Enter Your Mail!" />
                    </div>
                  </div>
                  <div class="col-xl-3 col-lg-4 col-md-4">
                    <div class="form-group m-0">
                      <button type="button" class="btn btn-dark fw-medium full-width">Submit<i
                          class="fa-solid fa-arrow-trend-up ms-2"></i></button>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>

        </div>
      </div>
    </div>
    </div>
  )
}

export default Newslatter
