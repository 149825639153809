import React from 'react';
// import './animation.css'; 
import './bootstrap.min.css';  
// import './bootstrap-icons.css'; 
// import './dropzone.min.css';  
// import './flatpickr.min.css';  
// import './lightbox.min.css';  
// import './magnifypopup.css';  
import './prism.css'; 
// import './rangeSlider.min.css';  
// import './select2.min.css';  
import './style.css'; 

 

import Why_choose from './Why_choose';
import PopularDestination from './PopularDestination';
import PopularRoutes from './PopularRoutes';
import Newslatter from './Newslatter';
import Footer from './Footer';
import Header from './Header';
import Banner from './Banner';


function Homepage() {
  return (
    <div className="homepage-container">
      
      <div className='homepage_container_head'>
      

        <Header/> 
        <Banner/> 
      </div>

      <Why_choose />
      <PopularDestination/>
      <PopularRoutes/> 
      <Newslatter/> 
      <Footer/> 
      
    </div>
  );
}

export default Homepage;
