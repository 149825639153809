


import React, { useState } from 'react';
import ReactSlider from 'react-slider';

const Price_range_flight = () => {
  const [minValue, setMinValue] = useState(5);
  const [maxValue, setMaxValue] = useState(95);

  const handleSliderChange = (values) => {
    setMinValue(values[0]);
    setMaxValue(values[1]);
  };

  

  return (
    <div className="price-range-slider">
      <h3>KWD{minValue} - KWD{maxValue}</h3>
      <ReactSlider
        className="horizontal-slider"
        thumbClassName="thumb"
        trackClassName="track"
        defaultValue={[minValue, maxValue]}
        min={0}
        max={100}
        ariaLabel={['Lower thumb', 'Upper thumb']}
        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
        onChange={handleSliderChange}
      />
    </div>
  );
};

export default Price_range_flight;

